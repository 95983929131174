.khts-new-nav-w-full {
    min-width: 100%;
}

.khts-new-nav-h-full {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.khts-new-nav-header {
    align-items: center;
    background-color: #fff;
    border-bottom: 0.05rem solid #8c8c8c52;
    border-top: 0.75rem solid #592c82;
    display: flex;
    flex-direction: row;
    height: 5.375rem;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 1000;
}

.khts-new-nav-header-menu {
    align-items: center;
    column-gap: 1rem;
    display: flex;
    flex-direction: row;
    padding-left: 1.5rem;
}

.khts-new-nav-app-logo {
    max-height: 2.5rem;
}

.khts-new-nav-app-brand {
    color: #353a40de;
    text-decoration: none;
    cursor: pointer;
}

.khts-new-nav-header-items {
    align-items: center;
    display: flex;
    flex-direction: row;
}

.khts-spacer {
    flex: 1 1 auto;
}

.khts-new-nav-document-body {
    display: flex;
    flex: 1 1 0;
    .mat-drawer-container {
        background-color: #fff;
    }
}

.khts-side-nav-container {
    width: 100%;
}

.khts-side-nav-inner {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
}

.khts-new-nav-main-content {
    display: flex;
    flex-direction: column;
}

.khts-new-nav-main-content-inner {
    display: flex;
    flex-direction: column;
    padding: 2.5rem 3.75rem;
}
