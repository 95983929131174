/* width */
.cdk-overlay-container ::-webkit-scrollbar,
::-webkit-scrollbar {
    width: 10px;
}
/* Track */
.cdk-overlay-container ::-webkit-scrollbar-track,
::-webkit-scrollbar-track {
    background: #f1f1f1 !important;
}
/* Handle */
.cdk-overlay-container ::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb {
    background: #888 !important;
}
/* Handle on hover */
.cdk-overlay-container ::-webkit-scrollbar-thumb:hover,
::-webkit-scrollbar-thumb:hover {
    background: #555 !important;
}
