.kh-site-tab-group {
    .mat-tab-header {
        z-index: 998;
        overflow: unset;

        background: #ffffff;
    }

    &.kh-collapsed-header .mat-tab-header {
        position: fixed;
        width: -webkit-fill-available;
        top: 10.25rem;
        margin-right: 3.75rem;
    }

    &.kh-site-tab-group--inactive-site.kh-collapsed-header .mat-tab-header {
        top: 11.75rem;
    }
}

.khts-mat-tab-group--left-align {
    .mat-tab-header {
        .mat-tab-labels {
            gap: 3rem;
            .mat-tab-label {
                padding: 0;
                width: auto;
                min-width: unset;
            }
        }
    }
    .mat-tab-body {
        padding-left: 0;
        padding-right: 0;
    }
}

.khts-mat-tab-group__body--padding-top {
    .mat-tab-body {
        padding-top: 2rem;
    }
}
