/* Smarts Form Builder Section */
.sfb_section {
    display: inline-flex;
    flex-direction: column;
    margin-bottom: 20px;
    flex-grow: 1;
}

.sfb_section_title {
    align-items: center;
    display: inline-flex;
}

.sfb_section_title_text {
    flex-grow: 1;
    margin-right: 20px;
}

.sfb_section_title_text .k-input {
    font-size: 20px;
    font-weight: 500;
}

.sfb_section_title_text .k-floating-label-container {
    font-size: 10px;
}

.sfb_section_container {
    border-radius: 0 0 8px 8px;
    border-style: solid;
    border-width: 0 1px 1px 1px;
    display: inline-flex;
    flex-direction: column;
    min-width: 100%;
    padding: 20px;
    row-gap: 20px;
}

.sfb_section_content {
    column-gap: 20px;
    grid-column-gap: 20px;
    display: inline-flex;
    flex-wrap: nowrap;
    align-items: stretch;
    justify-content: flex-start;
}
