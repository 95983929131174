.khts-mat-progress-bar {

    &.mat-progress-bar {
        border-radius: .5rem;
        height: 0.5rem;
        width: 100%;

        .mat-progress-bar-fill::after {
            background-color: var(--theme-color);
        }
    }

}
