/* Smarts Form Builder Colors */
body {
    --sfb-primary-background-button: #fff;
    --sfb-primary-background-container: #fff;
    --sfb-primary-border-container: #b5b6ba;
    --sfb-primary-button-icon: #3e3e3f;
    --sfb-primary-shadow: rgba(66, 69, 83, 0.1);
    --sfb-primary-text: #2d303f;

    --sfb-primary-background-content: #f5f5f5;
    --sfb-primary-background-header: #b5b6ba;
    --sfb-primary-border-content: #b5b6ba;
    --sfb-primary-border-header: #b5b6ba;
    --sfb-primary-icon-header: #2d303f;

    --sfb-secondary-background-content: #fff;
    --sfb-secondary-background-header: #c7c8ca;
    --sfb-secondary-border-content: #c7c8ca;
    --sfb-secondary-border-header: #c7c8ca;

    --sfb-tertiary-background-content: #fff;
    --sfb-tertiary-background-header: #d9dadd;
    --sfb-tertiary-border-content: #d9dadd;
    --sfb-tertiary-border-header: #d9dadd;

    --sfb-hover-background-content: #fff;
    --sfb-hover-background-header: #3176ea;
    --sfb-hover-border-content: #3176ea;
    --sfb-hover-border-header: #3176ea;
    --sfb-hover-icon-header: #fff;
    --sfb-hover-text: #fff;
}

/* Smarts Form Builder Containers */
.sfb_container {
    border-radius: 0 0 8px 8px;
    border-style: solid;
    border-width: 0 1px 1px 1px;
    min-width: 100%;
    padding: 20px;
}

.sfb_container_primary {
    background-color: var(--sfb-primary-background-content);
    border-color: var(--sfb-primary-border-container);
}

.sfb_container_secondary {
    background-color: var(--sfb-secondary-background-content);
    border-color: var(--sfb-secondary-border-content);
}

.sfb_container_tertiary {
    background-color: var(--sfb-tertiary-background-content);
    border-color: var(--sfb-tertiary-border-content);
}

.sfb_container_hover {
    background-color: var(--sfb-hover-background-content);
    border-color: var(--sfb-hover-border-content);
}

/* Smarts Form Builder Icons */
.sfb_icon {
    height: 16px;
    margin: 0 8px;
    width: 16px;
}

.sfb_icon_wrapper {
    align-items: center;
    display: inline-flex;
    height: 32px;
    justify-items: center;
    width: 32px;
}

.sfb_icon_primary {
    fill: var(--sfb-primary-icon-header);
}

.sfb_icon_secondary {
    fill: var(--sfb-secondary-icon-header);
}

.sfb_icon_tertiary {
    fill: var(--sfb-tertiary-icon-header);
}

.sfb_icon_hover {
    fill: var(--sfb-hover-icon-header);
}

/* Smarts Form Builder Buttons */
.sfb_btn {
    align-items: center;
    border-radius: 8px !important;
    cursor: pointer;
    display: inline-flex;
    font-size: 14px;
    font-weight: 500;
    margin: 0 2px;
    padding: 8px 20px;
    text-align: center;
    white-space: nowrap;
}

.sfb_btn_icon {
    height: 16px;
    margin: 0 8px 0 0;
    width: 16px;
}

.sfb_btn_primary {
    background: var(--sfb-primary-background-button);
    border: 1px solid var(--sfb-primary-border-content);
    box-shadow: 0px 4px 4px var(--sfb-primary-shadow);
    color: var(--sfb-primary-text);
}

.sfb_btn_enable_duplication {
    border: none;
    background-color: transparent;
    color: var(--sfb-primary-text);
    margin-right: 12px;
}

.sfb_btn_enable_duplication_toggle {
    margin-left: 12px;
}

.sfb_btn_bar {
    align-items: center;
    column-gap: 4px;
    display: inline-flex;
    justify-content: center;
    width: 100%;
}

.sfb_btn_bar_fields {
    min-width: 482px;
}

.sfb_button_add_section {
    background: var(--sfb-primary-background-button);
    border-radius: 8px !important;
    border: 1px dashed #575965;
    box-shadow: 0px 4px 4px var(--sfb-primary-shadow);
    margin: 20px 0;
    width: 100%;
}

/* Smarts Form Builder Titles */
.sfb_header_title {
    flex-grow: 1;
    font-size: 14px;
    font-weight: 500;
}

.sfb_title_field {
    display: inline-flex;
    border-bottom: 1px solid var(--sfb-primary-border-content);
    width: 100%;
}

.sfb_title_field_text {
    flex-grow: 1;
    width: 100%;
    min-width: 482px;
}

.sfb_title_field_text .k-input {
    font-size: 20px;
    font-weight: 500;
}

.sfb_title_field_text .k-floating-label-container {
    font-size: 10px;
}

.sfb_title_primary {
    color: var(--sfb-primary-text);
}

.sfb_title_secondary {
    color: var(--sfb-secondary-text);
}

.sfb_title_tertiary {
    color: var(--sfb-tertiary-text);
}

.sfb_title_hover {
    color: var(--sfb-hover-text);
}

/* Smarts Form Builder Headers */
.sfb_header {
    align-items: center;
    border-radius: 8px 8px 0 0;
    display: inline-flex;
    height: 32px;
    justify-content: right;
    width: 100%;
}

.sfb_header_bar {
    align-items: center;
    display: inline-flex;
    min-height: 32px;
    justify-content: right;
    box-shadow: 0px 4px 4px var(--sfb-primary-shadow);
    padding: 0 14px;
    width: 100%;
}

.sfb_header_button {
    border: none;
    background: none;
    margin: 0;
    padding: 0;
}

.sfb_header_primary {
    background: var(--sfb-primary-background-header);
    border-color: var(--sfb-primary-border-content);
}

.sfb_header_secondary {
    background: var(--sfb-secondary-background-header);
    border-color: var(--sfb-secondary-border-content);
}

.sfb_header_tertiary {
    background: var(--sfb-tertiary-background-header);
    border-color: var(--sfb-tertiary-border-content);
}

.sfb_header_hover {
    background: var(--sfb-hover-background-header);
    border-color: var(--sfb-hover-border-content);
}

/* Dark Mode */
.sfb_darkmode {
    opacity: 75%;
    transform: scale(0.75);
    filter: saturate(85%) blur(0.2px);
}

/* Smarts Form Builder Outer Container */
.smarts_form_outer_container {
    display: inline-flex;
    flex-direction: column;
    min-width: 100%;
}

.sfb_sections {
    display: inline-flex;
    flex-direction: column;
    min-width: 100%;
}

.sfb_inline_flex {
    display: inline-flex;
}

/* Material Overrides */
.mat-tab-body-content {
    overflow: auto !important;
}

/* experimental */
.kh_smarts_form_sections {
    display: flex;
    flex-grow: 1;
}

kh-smarts-form-sections > .cdk-drop-list {
    display: flex;
    align-items: stretch;
    flex-grow: 1;
    flex-direction: column;
}
