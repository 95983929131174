.kh-create-edit-site__mat-radio {
    &.mat-radio-button.mat-accent .mat-radio-checked .mat-radio-inner-circle {
        background-color: var(--theme-color);
    }

    &.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
        background-color: var(--theme-color);
    }

    &.mat-radio-button.mat-accent .mat-radio-inner-circle {
        background-color: var(--theme-color);
    }
}
