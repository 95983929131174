/* Smarts Form Builder Column */
.sfb_column {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    min-width: 524px;
}

.sfb_column_content {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    row-gap: 20px;
}
