//Material dialog
.mat-dialog-container {
    position: relative;
}

//golden standard for dialogs. Any new dialogs should use this class. See Sandbox for implementation
.khts-mat-dialog-title.mat-dialog-title {
}

.khts-mat-dialog-content.mat-dialog-content {
}

.khts-mat-dialog-actions.mat-dialog-actions {
    padding-bottom: 0.938rem;
    gap: 0.75rem;
}
