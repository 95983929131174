// Transitions and transformations
.kh-rotate {
    transform: rotate(180deg);
}

.kh-trans--fast {
    transition: all 0.25s ease-in-out;
}

.kh-trans--slow {
    transition: all 1s ease-in-out;
}

.kh-collapse {
    max-height: 0;
}
