.kh-create-edit-site-kendo-stepper {
    &.k-stepper .k-step-indicator {
        background-color: #c7c8ca;
        border-color: #c7c8ca;
    }

    &.k-stepper .k-step-current .k-step-indicator {
        background-color: var(--theme-color);
        border-color: var(--theme-color);
    }

    &.k-stepper .k-step-done .k-step-indicator {
        background-color: var(--theme-color);
        border-color: var(--theme-color);
    }

    .k-progressbar .k-state-selected {
        background-color: var(--theme-color);
    }
}
