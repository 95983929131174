.kh-project-notes-mat-buttons-toggle-group {
    &.mat-button-toggle-group {
        border: none;
        min-width: 0px;
        border-bottom-left-radius: 0px;
    }

    .mat-button-toggle-appearance-standard {
        border-color: white;
        border-width: 2px;
    }

    .mat-button-toggle {
        min-width: 75px;
        border-color: white !important;
        border-width: 1px;
        border-style: solid;
        border-bottom-color: #c7c8ca !important;
        border-bottom-style: solid !important;
        border-bottom-width: 1px !important;
    }

    .mat-button-toggle-checked {
        background-color: inherit;
        font-weight: bold;
        border-bottom: 3px solid var(--theme-color) !important;
    }
}
