.khts-nav-btn {
    &.mat-flat-button {
        background-color: transparent;
        border-radius: 0.5rem;
        cursor: pointer;
        display: flex;
        line-height: 3.5rem;
        width: 100%;
    }
    .mat-button-wrapper {
        align-items: center;
        column-gap: 0.5rem;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
    }
    .mat-icon {
        font-family: 'Material Icons Outlined';
    }
}

.khts-nav-btn--primary {
    &:hover {
        background-color: #f5f5f6;
    }
}

.khts-nav-btn--secondary {
    &.mat-flat-button {
        line-height: 2.5rem;
    }
    &:hover {
        background-color: #f0f0f0;
    }
    .mat-icon {
        transform: scale(0.75);
    }
}

.khts-nav-btn--active {
    &.mat-flat-button {
        background-color: #ededee;
    }
    .mat-button-wrapper {
        font-weight: 700;
    }
    .mat-icon {
        font-family: 'Material Icons';
    }
}

.khts-nav-btn--active-adminlink {
    &.mat-flat-button {
        background-color: #e0e0e2;
    }
    .mat-button-wrapper {
        font-weight: 700;
    }
    .mat-icon {
        font-family: 'Material Icons';
    }
}

.khts-nav-btn--centered {
    &.mat-flat-button {
        border: 1px solid black;
        justify-content: center;
        line-height: 2rem;
    }
    .mat-button-wrapper {
        min-height: 2rem;
    }
}

.khts-nav-btn--block {
    &.mat-flat-button {
        width: 100%;
    }
}

.khts-nav-btn--rail-primary {
    &.mat-flat-button {
        align-items: center;
        border-radius: 2rem;
        cursor: pointer;
        justify-content: center;
        min-height: 3.5rem;
    }
}

.khts-nav-btn--rail-secondary {
    &.mat-flat-button {
        align-items: center;
        border-radius: 2rem;
        cursor: pointer;
        justify-content: center;
        min-height: 2.5rem;
    }
}

.khts-nav-link {
    text-decoration: none;
}

.khts-mat-divider {
    &.mat-divider {
        border-color: #a19a9a;
        margin: 0 1rem;
    }
}

.khts-menu-container {
    padding: 0.75rem;
}

.khts-menu-container--scrollable {
    overflow-y: auto;
}

.khts-right-menu {
    left: 5rem;
    position: absolute;
    top: -3rem;
}

.khts-right-menu--expanded {
    left: 14.5rem;
}

.khts-right-menu--text {
    font-weight: 700;
    padding: 0.1rem 1rem;
}
